<template>
	<section ref="qrPanel" class="qr-section">
		<client-only>
			<div class="qr-panel">
				<div class="row">
					<form class="booking bookForm">
						<div class="columns column3">
							<select v-model="state.pickedLocation" class="location">
								<option value="" selected disabled>
									{{ $t('chooseHotel') }}
								</option>
								<option v-for="hotel in items" :key="hotel.ID" :value="hotel.bookingID">
									{{ hotel.header }}
								</option>
							</select>
						</div>
						<div class="border" />
						<div class="columns column3">
							<date-picker
								v-model:value="state.arrival"
								:clearable="false"
								:disabled-date="disableArrivalDates"
								value-type="format"
								format="YYYY-MM-DD"
								:placeholder="bookingForm.checkin"
								:lang="datepickerLang"
								@pick="pickArrival"
							/>
						</div>
						<div class="border" />
						<div class="columns column3">
							<date-picker
								v-model:value="state.departure"
								:open="state.openDeparture"
								:disabled-date="disableDepartureDates"
								value-type="format"
								format="YYYY-MM-DD"
								:placeholder="bookingForm.checkout"
								:default-value="state.pickedArrivalDate ?? new Date()"
								:lang="datepickerLang"
								@open="(state.openDeparture = true)"
								@close="(state.openDeparture = false)"
							/>
						</div>
						<div class="columns column3">
							<a
								type="submit"
								class="button book-now cta qr"
								:value="bookingForm.availability"
								@click="openBookingEngine"
							>
								{{ bookingForm.availability }}
							</a>
						</div>
					</form>
				</div>
			</div>
		</client-only>
	</section>
</template>

<script setup>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import datePickerTranslations from '~/config/datePicker.js';
import applyTracking from '@becurious/applytracking';
import { languageToLocale } from '@becurious/languagetolocale';

const { locale } = useI18n();

defineProps({
	items: { type: Array, default: () => [] },
	bookingForm: { type: Object, default: () => ({}) },
});

const state = reactive({
	arrival: null,
	departure: null,
	openDeparture: false,
	pickedArrivalDate: null,
	fallbackLanguage: 'en',
	pickedLocation: '',
});

const qrPanel = ref(null);

const datepickerLang = computed(() => datePickerTranslations[locale] ?? datePickerTranslations.en);

const pickArrival = (date) => {
	state.departure = '';
	state.pickedArrivalDate = date;
	state.openDeparture = true;
};

const disableArrivalDates = (date) => {
	const today = new Date();

	return date < today.setDate(today.getDate() - 1);
};

const disableDepartureDates = (date) => {
	if (!state.pickedArrivalDate) {
		return disableArrivalDates(date);
	}

	return date <= state.pickedArrivalDate;
};

const openBookingEngine = () => {
	const url = new URL(state.pickedLocation);

	url.search = new URL(document.location).searchParams;

	if (state.pickedLocation.includes('distributor')) {
		const language = languageToLocale(locale.value ?? state.fallbackLanguage).replace('_', '-');

		url.searchParams.append('language', language);

		if (state.arrival) {
			url.searchParams.append('mewsStart', state.arrival);
		}

		if (state.departure) {
			url.searchParams.append('mewsEnd', state.departure);
		}

		const bookingUrl = applyTracking(url.href);

		window.open(bookingUrl);
	} else {
		if (state.arrival) {
			const arrivalFirst = state.arrival.slice(8, 10);
			const arrivalMiddle = state.arrival.slice(5, 7);
			const arrivalLast = state.arrival.slice(0, 4);

			const arrivalNew = arrivalFirst + '-' + arrivalMiddle + '-' + arrivalLast;

			url.searchParams.append('arrival', arrivalNew);
		}

		if (state.departure) {
			const departureFirst = state.departure.slice(8, 10);
			const departureMiddle = state.departure.slice(5, 7);
			const departureLast = state.departure.slice(0, 4);

			const departureNew = departureFirst + '-' + departureMiddle + '-' + departureLast;

			url.searchParams.append('departure', departureNew);
		}

		const bookingUrl = applyTracking(url.href);

		const bookingUrlFix = bookingUrl.replace('?', '/');
		const bookingUrlFixed = bookingUrlFix.replace('&', '/');
		const bookingUrlFixedMore = bookingUrlFixed.replace('=', '/');
		const bookingUrlFull = bookingUrlFixedMore.replace('=', '/');

		window.open(bookingUrlFull);
	}
};

onMounted(() => {
	qrPanel.value.classList.add('mounted');
});
</script>

<style>
.mx-datepicker {
	width: 100%;
}

.mx-input,
.qr-panel select {
	margin: 0 !important;
	width: 100% !important;
	padding: 15px 40px 15px 15px !important;
	font-size: 14px;
	height: 62px !important;
	text-transform: uppercase;
	border: none !important;
	font-weight: 600;
	color: #000;
}

.mx-datepicker svg {
	color: var(--header-color);
}
</style>

<style lang="scss" scoped>
.qr-section {
	text-align: center;
	margin: 0 auto;
	position: absolute;
	bottom: 80px;
	left: 0;
	right: 0;
	padding: 0;
	float: left;
	width: 100%;
	max-width: 870px;
	z-index: 10;
}

.qr-panel {
	float: none;
	padding: 0;
	width: 100%;
	margin: 0 auto;
	display: inline-block;
	position: relative;
	z-index: 2;
	text-align: center;
	background: #fff;

	::placeholder {
		color: #000;
	}

	.row {
		width: 100%;
		padding: 0;
	}

	.columns {
		padding: 0 7px 0 0;
		width: 29%;

		&:last-child {
			width: calc(42% + 1px);
			padding: 0;
		}
	}

	.border {
		width: 1px;
		background: var(--header-color);
		height: 34px;
	}

	form {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
	}
}

.qr-panel input,
.qr-panel select {
	margin: 0 !important;
	width: 100% !important;
	padding: 15px 40px 15px 15px !important;
	height: 62px !important;
	border: none;
	text-transform: uppercase;
}

.qr-panel select {
	padding: 15px 20px 15px 15px !important;
}

.qr-panel .button {
	padding: 0 10px !important;
	text-transform: uppercase;
	border: none !important;
	vertical-align: top;
	width: 100%;
	line-height: 62px;
	height: 62px;
}
</style>
